<style lang="less" scoped>
@import url("../assets/less/base.less");
.download {
  position: relative;
  background: url("../assets/img/免费下载_slices/img_download_phone.png")
    no-repeat #fed501;
  background-position: calc(50% + 200px) bottom;
  height: calc(100vh - 100px);
  min-height: 768px;
  .app-content {
    position: relative;
    height: calc(100vh - 100px);
    .titles {
      padding-top: 206px;
      h1 {
        font-size: 56px;
        font-family: "future";
        font-weight: bold;
        color: #333333;
        line-height: 77px;
      }
    }
    .download-btns {
      display: flex;
      align-items: center;
      height: 180px;
      margin-top: 15px;
      .-left {
        width: 156px;
        height: 180px;
        background:url("../assets/img/免费下载_slices/img_download_qr.png");
      }
      .-right {
        padding: 18px 24px;
        a {
          display: block;
          font-size: 0;
          width: 160px;
          height: 60px;
          background-size: 100%;
          background-image: url("../assets/img/免费下载_slices/btn_download_ios_white.png");
          &:last-child {
            background-image: url("../assets/img/免费下载_slices/btn_download_android_white.png");
            margin-top: 24px;
          }
        }
      }
    }
    .icon_1{
      width: 170px;
      height: 170px;
      background: url("../assets/img/免费下载_slices/img_shoppingcar.png");
      position: absolute;
      bottom: 78px;
      right: 456px;
      animation: move1 3s infinite linear;
    }
    .icon_2{
      width: 150px;
      height: 150px;
      background: url("../assets/img/免费下载_slices/img_bag.png");
      position: absolute;
      bottom: 219px;
      right: 0;
      animation: move1 3s infinite linear;
    }
  }
}
@keyframes move1 {
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(20px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0);
  }
}
@keyframes move2 {
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-15px);
  }
  75%{
    transform: translateY(15px);
  }
  100%{
    transform: translateY(0);
  }
}
</style>
<template>
  <div class="download">
    <div class="app-content">
      <div class="titles">
        <h1>生活省钱助手</h1>
        <h1>省钱，是一种生活态度！</h1>
      </div>
      <div class="download-btns">
        <div class="-left"></div>
        <div class="-right">
          <a href="https://apps.apple.com/cn/app/id1297227820" target="_blank"
            >ios下载</a
          >
          <a
            href="https://sj.qq.com/myapp/detail.htm?apkName=com.xiaoxiongyhh&info=E1A0FBB267301F7AFE6376C2937074B8"
            target="_blank"
            >Android下载</a
          >
        </div>
      </div>
      <div class="icon_1"></div>
      <div class="icon_2"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {},
  mounted() {},
  methods: {},
};
</script>
