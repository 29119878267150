<style lang="less">
@import url("../../../node_modules/swiper/swiper.less");
.join {
  overflow: auto;
  .model-1 {
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      width: 40px;
      height: 40px;
      background: url("../../assets/img/首页_slices/ic_next.png");
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
    .swiper-button-prev {
      transform: translateY(-50%) rotateY(180deg);
      right: auto;
      left: 10px;
    }
    .swiper-slide {
      width: 384px;
      height: 220px;
      background: #f5f5f5;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  .model-2 {
    h1 {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin: 47px 0 20px;
      background: #fff;
    }
    .tab {
      border-radius: 16px 16px 0px 0px;
      .th,
      .tb-cells {
        display: flex;
        align-items: center;
        line-height: 50px;
        font-size: 14px;
        color: #333333;
        .cell {
          flex: 1;
          text-align: center;
        }
      }
      .th {
        background: #333333;
        color: #fff;
        .cell {
          flex: 1;
        }
      }
      .tb {
        border-bottom: 1px solid #eee;
        .tb-cells {
          .cell {
            &:last-child {
              position: relative;
              &.active {
                &::after {
                  transform: rotateX(0);
                }
              }
              &::after {
                content: "";
                width: 24px;
                height: 24px;
                position: absolute;
                right: 24px;
                top: 13px;
                background: url("../../assets/img/首页_slices/ic_up.png");
                background-size: 100% 100%;
                transform: rotateX(180deg);
              }
            }
          }
        }
        .tb-content {
          background: #f6f6f6;
          padding: 20px 24px 20px 136px;
          h3,
          p {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          p {
            & + h3 {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="join">
    <!-- 轮播 -->
    <div class="model-1">
      <Swiper :slides-per-view="2.5" :space-between="24" navigation>
        <SwiperSlide
          v-for="(item, index) in photos"
          :key="index"
          :style="{ backgroundImage: `url(${item})` }"
        ></SwiperSlide>
      </Swiper>
    </div>
    <!-- 热招 -->
    <div class="model-2">
      <h1>热招职位</h1>
      <div class="tab">
        <div class="th">
          <div class="cell">职位名称</div>
          <div class="cell">职位类别</div>
          <div class="cell">工作地点</div>
        </div>
        <div class="tb" @click="job1 = !job1">
          <div class="tb-cells">
            <div class="cell">算法工程师</div>
            <div class="cell">研发</div>
            <div class="cell" :class="{ active: job1 }">深圳</div>
          </div>
          <div v-if="job1" class="tb-content">
            <h3>职业描述：</h3>
            <p>
              1、负责自然语言处理与深度学习相关技术的建模与实现<br />
              2、建设基础电商分析工具，设计与实现搜索、分词、分类等系统相关模块<br />
              3、结合实际需求与数据，解决业务具体的NLP问题
            </p>
            <h3>职业要求：</h3>
            <p>
              1、计算机/统计/数学相关专业本科及以上学历（硬性要求统招本科以上）<br />
              2、良好的编程基础和工程能力，至少熟悉一门编程语言，如python、go、c++等；<br />
              3、熟悉深度学习以及常见机器学习算法的原理及实现，能熟练运用聚类、分类、回归、排序等模型解决实际问题；<br />
              4、对自然语言处理相关的分词、词性标注等基础算法技术有实践经验；<br />
              5、有电商类搜索、关联、内容理解等相关工作经验优先；<br />
              6、有良好的团队合作意识和沟通能力，自驱能力强。
            </p>
          </div>
        </div>
        <div class="tb" @click="job2 = !job2">
          <div class="tb-cells">
            <div class="cell">PHP高级开发工程师</div>
            <div class="cell">研发</div>
            <div class="cell" :class="{ active: job2 }">深圳</div>
          </div>
          <div v-if="job2" class="tb-content">
            <h3>任职要求:</h3>
            <p>
              年PHP经验 精通lnmp开发<br />
              Laravel，Yii，ThinkPHP至少熟练使用其中两个，精通一个<br />
              深入理解HTTP协议及原理 熟练使用Linux常用命令 熟练使用Git及Git
              flow<br />
              熟练使用Mysql及索引原理，有至少百万级数据表优化经验<br />
              熟悉常见web安全风险及原理 熟悉业务开发流程和项目管理流程<br />
              (以下非必须，要有学习热情和能力) 开发过Composer包<br />
              熟悉Docker容器技术及Docker Compose编排工具<br />
              了解使用过前端至少一个MVVM框架
            </p>
            <h3>岗位职责：</h3>
            <p>
              1.负责项目的需求分析与开发 2.系统API接口设计开发及维护<br />
              3.对现有项目进行优化重构和二次开发
            </p>
          </div>
        </div>
        <div @click="job3 = !job3" class="tb">
          <div class="tb-cells">
            <div class="cell">测试工程师</div>
            <div class="cell">研发</div>
            <div class="cell" :class="{ active: job3 }">深圳</div>
          </div>
          <div v-if="job3" class="tb-content">
            <h3>职位描述:</h3>
            <p>
              1.对公司APP的全流程测试工作，包括需求分析评审，制定测试计划和测试方案，设计测试用例，实施测试；评审测试计划和方案，指导并解决相应的技术难点。<br />
              2.根据项目需求进行功能测试,接口测试,性能测试,安全测试等.<br />
              3.与产品开发等沟通测试内容,确保测试质量及提出优化建议.<br />
              4.阶段性总结并改进测试流程制度，减少测试风险，提高测试工作效率，保证测试质量。
            </p>
            <h3>任职资格：</h3>
            <p>
              1.精通APP的测试规范和开发流程，熟练使用常用性能测试工具（loadrunner、Jmeter等),熟练编写自动化脚本、性能测试脚本，开发测试工具，熟练使用GIT、SVN等版本管理工具<br />
              2.全日制统招计算机等相关专业本科（硬性要求）及以上学历，3年及以上开发测试工作经验，1年及以上测试团队管理经验,有性能、安全、白盒测试等专业测试领域经验者优先<br />
              3.具有高度的责任心，良好的团队管理能力和人才培养能力，出色的规划和执行能力，沟通协作能力，逻辑分析能力和文档编写能力
            </p>
          </div>
        </div>
        <div @click="job4 = !job4" class="tb">
          <div class="tb-cells">
            <div class="cell">产品经理</div>
            <div class="cell">运营</div>
            <div class="cell" :class="{ active: job4 }">深圳</div>
          </div>
          <div v-if="job4" class="tb-content">
            <h3>职位描述:</h3>
            <p>
              1、负责公司移动端应用的产品策划和优化工作；<br />
              2、通过市场分析、用户分析等，提炼用户需求，制作产品需求计划和模型，撰写产品功能需求说明书；<br />
              3、制定产品的中长期规划，推动产品的快速健康发展，树立优质口碑和形象；<br />
              4、通过市场调查和运营数据分析，对产品发展情况给予评估，优化流程，对各阶段的产品功能和特性进行取舍；<br />
              5、负责跨部门协调和沟通，推动UI、开发、测试等人员紧密合作达成产品目标；<br />
              6、了解互联网及移动互联网领域的相关和类似产品，分析行业发展热点，并提出创新或改进需求。
            </p>
            <h3>任职要求：</h3>
            <p>
              1、本科及以上学历，3年以上相关产品设计工作经验；<br />
              2、有优惠券返利和相关运营经验的优先；<br />
              3、精通互联网产品交互设计的相关流程，包括功能分析、用户角色分析、原型设计、界面开发、易用性测试等；<br />
              4、精通Axure、Xmind、Office等办公软件，能独立输出产品交互原型、产品流程、产品说明等PRD文档；<br />
              5、具备良好的数据与竞争对手分析能力，能够对自有产品提出整改计划；<br />
              6、有较强的敬业精神，责任心及良好的沟通能力和团队合作精神，具有较强的学习能力和洞察力。
            </p>
          </div>
        </div>
        <div @click="job5 = !job5" class="tb">
          <div class="tb-cells">
            <div class="cell">推广运营经理</div>
            <div class="cell">运营</div>
            <div class="cell" :class="{ active: job5 }">深圳</div>
          </div>
          <div v-if="job5" class="tb-content">
            <h3>工作内容：</h3>
            <p>
              1、负责APP的推广及应用市场的运营<br />
              2、负责APP与各应用合作，信息流广告渠道投放，合理制定投放计划。<br />
              3、负责应用再IOS和安卓的ASO优化，时刻关注应用商店排名算法变化，实现下载量、安装量、活跃度及roi等指标达成，为优化效果及目标负责。<br />
              4、对投放数据进行分析，并制定优化策略。<br />
              5、研究相关竞品推广渠道以及推广策略，合理调整建议和内容关键词策略
            </p>
            <h3>任职要求：</h3>
            <p>
              1、全日制统招本科以上学历；<br />
              2、3年以上互联网产品运营推广经验；<br />
              3、使用Photoshop等宣传海报制作工具优先；<br />
              4、有app运营推广经验优先；<br />
              5、具备良好的独立分析和解决问题的能力，责任心强，有自主服务意识。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation]);
import "swiper/swiper.less";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const photos = Array.from({ length: 8 }).map((item, index) => {
      return require(`../../assets/img/官网照片/photo_0${index + 1}.png`);
    });
    return {
      photos: photos,
      job1: true,
      job2: false,
      job3: false,
      job4: false,
      job5: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // new Swiper(".swiper-container", {
      //   slidesPerView: "auto",
      //   spaceBetween: 24,
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      // });
    });
  },
  methods: {},
};
</script>