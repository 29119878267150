<style lang="less" scoped>
.cooperation {
  overflow: auto;

  .-left {
    float: left;
    .msg {
      display: flex;
      align-items: center;
      margin-top: 16px;
      line-height: 40px;
      .label {
        width: 96px;
        height: 154px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-right: 24px;
      }
      .value {
        width: 329px;
        height: 154px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .-right {
    float: right;
    width: 384px;
    height: 210px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #eeeeee;
    .-top {
      display: flex;
      align-items: center;
      padding: 29px 32px;
      border-bottom: 1px solid #eeeeee;
      .icon {
        width: 60px;
        height: 60px;
        margin-right: 12px;
      }
      .tip {
        font-size: 16px;
        color: #999999;
        margin-top: 12px;
      }
    }
    .btn {
      width: 130px;
      height: 50px;
      border-radius: 16px;
      border: 1px solid #333333;
      font-size: 16px;
      color: #302600;
      line-height: 50px;
      margin: 0 auto;
      text-align: center;
      margin-top: 19px;
    }
  }
}
</style>
<template>
  <div class="cooperation">
    <div class="-left">
      <img src="../../assets/img/首页_slices/img_hezuo_pic.png" alt="" />
      <div class="msg">
        <div class="label">
          工作时间： <br />联系地址： <br />联系电话： <br />微信号码：
        </div>
        <div class="value">
          周一至周五，上午9:00-18:00（节假日除外）<br />
          深圳市南山区西丽街道TCL国际E城G3栋601室<br />
          18601671893<br />
          18601671893
        </div>
      </div>
    </div>
    <div class="-right">
      <div class="-top">
        <img
          class="icon"
          src="../../assets/img/首页_slices/ic_hezuo_cicle.png"
          alt=""
        />
        <div>
          <div class="title">商家合作</div>
          <div class="tip">淘宝天猫、京东店铺合作入口</div>
        </div>
      </div>
      <div class="btn" @click="openSign">立即报名</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    openSign() {
      window.open("https://wenjuan.feishu.cn/m?t=sd6EeCBhDGpi-izib");
    },
  },
};
</script>