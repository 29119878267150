<style lang="less" scoped>
@import url("../assets/less/base.less");
.about {
  border-top: 80px solid #fed501;
  .app-content {
    overflow: auto;
    padding-top: 24px;
    .nav {
      width: 178px;
      background: #ffffff;
      border-radius: 20px;
      border: 1px solid #eeeeee;
      padding: 28px 0;
      float: left;
      nav {
        padding-left: 22px;
        display: flex;
        align-items: center;
        opacity: 0.5;
        &.active {
          position: relative;
          opacity: 1;
          &::after {
            position: absolute;
            content: "";
            left: 0;
            width: 4px;
            height: 20px;
            background: #ffca00;
            top: 2px;
          }
        }
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          background-size: 100% 100%;
          margin-right: 24px;
        }
        &:nth-child(1) {
          &::before {
            background-image: url("../assets/img/首页_slices/ic_hezuo_balck.png");
          }
        }
        &:nth-child(3) {
          &::before {
            background-image: url("../assets/img/首页_slices/ic_contact_black.png");
          }
        }
        &:nth-child(2) {
          &::before {
            background-image: url("../assets/img/首页_slices/ic_addus_black.png");
          }
        }
        & + nav {
          margin-top: 46px;
        }
      }
    }
    .router-content{
      width: 996px;
      float: right;
    }
  }
}
</style>
<template>
  <div class="about">
    <div class="app-content">
      <div class="nav">
        <nav
          v-for="(item, index) in nav"
          :key="index"
          :class="{ active: navIndex === index }"
          @click="pageTo(item)"
        >
          {{ item.text }}
        </nav>
      </div>
      <div class="router-content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeRouteUpdate(to) {
    this.changeNavIndex(to.name);
  },
  data() {
    return {
      navIndex: 0,
      nav: [
        { text: "商务合作", name: "Cooperation" },
        { text: "加入我们", name: "JoinUs" },
        { text: "联系我们", name: "ContactUs" },
      ],
    };
  },
  mounted() {
    this.changeNavIndex(this.$route.name);
  },
  methods: {
    pageTo(item) {
      this.$router.push({ name: item.name });
    },
    changeNavIndex(name) {
      switch (name) {
        case "Cooperation":
          this.navIndex = 0;
          break;
        case "JoinUs":
          this.navIndex = 1;
          break;
        case "ContactUs":
          this.navIndex = 2;
          break;
        default:
          break;
      }
    },
  },
};
</script>
